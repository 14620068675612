import 'jquery-ui/dist/jquery-ui.min.js';
import 'jquery-ui/themes/base/base.css';
import 'jquery-ui/themes/base/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flatpickr/dist/flatpickr.min.js';
import 'flatpickr/dist/flatpickr.min.css';
import 'jquery-ui-touch-punch';
import 'autosize/dist/autosize.min.js';
import 'dropzone/dist/dropzone-min.js';
import 'dropzone/dist/dropzone.css';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import 'quill/dist/quill.js';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'select2/dist/js/select2.min.js';
import 'select2/dist/css/select2.min.css';
import 'jquery-blockui/jquery.blockUI.js';
import '../../js/jquery.countdown.js';
window.Toastify = require('toastify-js/src/toastify');
import 'toastify-js/src/toastify.css';
window.FullCalendar = require('fullcalendar/index.js');
import 'code-prettify/src/run_prettify.js';
import 'code-prettify/src/prettify.css';
import 'list.js/dist/list.js';


