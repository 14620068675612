/* jshint esversion:6 */
/* globals passwordPolicyRegex */

$(document).ready(function(){
    function checkPasswordPolicy(string, regexString) {

        var PASSWORD_POLICY_REGEX_ARRAY = JSON.parse(regexString);

        var errorMessage = '';
        PASSWORD_POLICY_REGEX_ARRAY.forEach(function (r) {
            if (!testRegex(string, r['regex_pattern_for_js'])) {
                errorMessage += '<strong>' + r['message'] + '</strong><br/>';
            }
        });
        return errorMessage;
    }

    function testRegex(string, regex) {

        var regexExp = new RegExp(regex);

        if (regexExp.test(string)) {
            return true;
        }
        return false;

    }

    function checkPassword(){
        let password = $('#password');
        let passwordRepeat = $('#password_repeat');
        let passwordAlert =  $('#password-policy-info');
        let passwordValue = password.val();
        let passwordRepeatValue = passwordRepeat.val();
        //let regex_pattern = password_policy_regex.replace(new RegExp("\\\\", "g"), "");
        let regexPattern = passwordPolicyRegex;

        let errorMessage = '';

        //No error is shown if password is blank
        if(passwordValue === ''){
            password.removeClass('error-border success-border');
            passwordRepeat.removeClass('error-border success-border');
            passwordAlert.hide();
            return false;
        }

        //Check if the password is suitable for our password policy.
        errorMessage = checkPasswordPolicy(passwordValue,regexPattern);
        if(errorMessage !== ''){
            password.addClass('error-border');
        } else {
            password.removeClass('error-border').addClass('success-border');
        }

        //Checking to see if password and password repeat are the same
        if(passwordValue !== passwordRepeatValue){
            errorMessage += 'Password and password repeat must be same';
            passwordRepeat.addClass('error-border');
        }

        //Error borders are hidden if there are no errors
        if(errorMessage === ''){
            passwordAlert.html('Your password meets our password policy.').removeClass('alert-warning').addClass('alert-success').show();
            password.removeClass('error-border').addClass('success-border');
            passwordRepeat.removeClass('error-border').addClass('success-border');
            return true;
        } else {
            passwordAlert.html(errorMessage).removeClass('alert-success').addClass('alert-warning').show();
            return false;
        }
    }

    window.checkPasswordPolicy = checkPasswordPolicy;
    window.testRegex = testRegex;
    window.checkPassword = checkPassword;
});