/* jshint esversion:6 */
/* globals ajaxLogin */
/* globals home */
/* globals forgotPassword */
/* globals activeDirectoryPolicyRegex */


import '../css/login.scss';
import '../../../../../../../system/library/password/password-policy.js';

$(document).ready(function(){
    $('#splash').hide();

    var position_1 = 'Sign In';
    var position_2 = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> Please Wait..</span>';


    function checkLogin() {
        var username = $('#username').val();
        var password = $('#password').val();
        var g_recaptcha_response = $('.g-recaptcha-response').val();

        username = username.trim(username);
        password = password.trim(password);

        if (username.length <= 0 || password.length <= 0) {
            $('.toast-title-js').html('Error');
            $('.toast-body').html('');
            $('.toast').show();
        }

        var active_directory_company_control_text = checkActiveDirectoryPolicy(username);

        if (active_directory_company_control_text !== '') {
            $('.toast-title-js').html('Error');
            $('.toast-body').html(active_directory_company_control_text);
            $('.toast').show();
            return false;
        }

        requestLogin(username, password, g_recaptcha_response);
    }

    function requestLogin(username, password, g_recaptcha_response) {
        $.ajax({
            url: ajaxLogin,
            type: 'POST',
            data: 'username=' + username + '&password=' + password + '&g-recaptcha-response=' + g_recaptcha_response,

            beforeSend: function () {
                $("login-button").html(position_2);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                toggleForget();
                $('.toast-title-js').html('Error');
                $('.toast-body').html('Please update your password.');
                $('.toast').show();
            },
            success: function (req) {
                if (req.successful) {
                    window.location = home;
                } else {
                    loginAlert(req.message);
                    if (req.data >= 3) {
                        window.setTimeout(function () {
                            window.location.reload();
                        }, 3000);
                    }
                    $('.toast-title-js').html('Error');
                    $('.toast-body').html(req.message);
                    $('.toast').show();
                }
            },
            complete: function () {
                $('login-button').html(position_1);
            }
        });
    }

    function loginAlert(message) {
        let alert = '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>' + message + '</strong><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        $('#login-alert').html(alert);
    }

    function toggleForget() {
        $('#forget-form').toggle();
        $('#login-form').toggle();
    }

    function forgotPasswordSend() {
        var email = $('#forgot-email').val();

        if (email.length > 0) {
            $.ajax({
                url: forgotPassword,
                type: 'POST',
                data: 'email=' + email,
                success: function (req) {
                    if (req.successful) {
                        $('.toast-title-js').html("Success");
                        $('.toast-body').html(req.message);
                        $('.toast').show();
                        toggleForget();
                    } else {
                        $('.toast-title-js').html("Error");
                        $('.toast-body').html(req.message);
                        $('.toast').show();
                    }

                    $("#forgot-email").val('');
                }
            });
        } else {
            $('.toast-title-js').html("Error");
            $('.toast-body').html("Please fill the form.");
            $('.toast').show();
        }
    }

    function checkActiveDirectoryPolicy(string) {

        var REGEX = activeDirectoryPolicyRegex;

        var POLICY_REGEX_ARRAY = JSON.parse(REGEX);

        var error_message = '';
        POLICY_REGEX_ARRAY.forEach(function (r) {
            if (testRegex(string, r['regex_pattern_for_js'])) {
                error_message += "Please select your company to Log In with your Active Directory credentials.";
            }
        });
        return error_message;
    }

    document.querySelector('#password').addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            checkLogin();
        }
    });

    window.checkLogin = checkLogin;
    window.requestLogin = requestLogin;
    window.loginAlert = loginAlert;
    window.toggleForget = toggleForget;
    window.forgotPasswordSend = forgotPasswordSend;
    window.checkActiveDirectoryPolicy = checkActiveDirectoryPolicy;

})